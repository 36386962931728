<template>

    <b-card no-body>
        <b-card-header>
            <b-card-title>
                Пользователи
            </b-card-title>

            <b-card-text style="display: flex">
                <div class="d-flex w-100 justify-content-between" style="align-items: center;">
                    <span class="mb-0 mr-1">
                        С покупками
                    </span>
                    <div class="d-flex ml-1 mr-1">
                        <b-form-checkbox
                            class="ml-1"
                            name="is-buy"
                            v-model="filter.isBuy"
                            @change="get(true)"
                            switch
                            style="right: 44px;"
                        />
                    </div>
                </div>
                <b-form-input  type="text" placeholder="Поиск" @change="get(true)" v-model="searchUsers" size="sm" style="border-radius: 100px;"  />
            </b-card-text>
        </b-card-header>
        <b-skeleton-table v-if='is_loading' animation="fade" :columns='6' :rows='5'>
            
        </b-skeleton-table>
        <b-table-simple v-else responsive>
            <b-thead>
                <b-tr>
                    <b-th class="text-nowrap">Пользователь
                        <feather-icon
                            v-if="orderBy.column === 'id' && orderBy.sortBy == 'desc'"
                            icon="ChevronDownIcon"
                            size="16"
                            @click="updateOrderBy('id')"
                            style="cursor:pointer"
                        />
                        <feather-icon
                            v-else-if="orderBy.column === 'id' && orderBy.sortBy == 'asc'"
                            icon="ChevronUpIcon"
                            size="16"
                            @click="updateOrderBy('id')"
                            style="cursor:pointer"
                        />
                        <feather-icon
                            v-else
                            icon="CodeIcon"
                            size="12"
                            @click="updateOrderBy('id')"
                            style="cursor:pointer; transform: rotate(90deg);"
                        />
                    </b-th>
                    <b-th class="text-nowrap">Почта</b-th>
                    <b-th class="text-nowrap">Инвестиции
                        <feather-icon
                            v-if="orderBy.column === 'investments' && orderBy.sortBy == 'desc'"
                            icon="ChevronDownIcon"
                            size="16"
                            @click="updateOrderBy('investments')"
                            style="cursor:pointer"
                        />
                        <feather-icon
                            v-else-if="orderBy.column === 'investments' && orderBy.sortBy == 'asc'"
                            icon="ChevronUpIcon"
                            size="16"
                            @click="updateOrderBy('investments')"
                            style="cursor:pointer"
                        />
                        <feather-icon
                            v-else
                            icon="CodeIcon"
                            size="12"
                            @click="updateOrderBy('investments')"
                            style="cursor:pointer; transform: rotate(90deg);"
                        />
                    </b-th>
                    <b-th class="text-nowrap">Хэшрейт
                        <feather-icon
                            v-if="orderBy.column === 'ths' && orderBy.sortBy == 'desc'"
                            icon="ChevronDownIcon"
                            size="16"
                            @click="updateOrderBy('ths')"
                            style="cursor:pointer"
                        />
                        <feather-icon
                            v-else-if="orderBy.column === 'ths' && orderBy.sortBy == 'asc'"
                            icon="ChevronUpIcon"
                            size="16"
                            @click="updateOrderBy('ths')"
                            style="cursor:pointer"
                        />
                        <feather-icon
                            v-else
                            icon="CodeIcon"
                            size="12"
                            @click="updateOrderBy('ths')"
                            style="cursor:pointer; transform: rotate(90deg);"
                        />
                    </b-th>
                    <b-th>Роль</b-th>
                    <b-th>Дата регистрации</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="user in dataSource.items" :key="user.id" v-ripple @click="$router.push({ name: 'admin-users-view', params: { id: user.id }})" class=" font-weight-normal cursor-pointer">
                    <b-th class="font-weight-normal text-nowrap">
                        <div class="d-flex">
                            <b-avatar
                                :variant="Users.utils.resolveAvatarColor( user.id )"
                                :text="Users.utils.capitalize(`${user.firstName}`, 1 )"
                            />
                            <div class="d-flex flex-column" style="margin-left: 12px;">
                                <span class="font-weight-bolder text-primary text-capitalize m-0">
                                    {{ user.firstName }} {{ user.lastName }}
                                </span>
                                <span class="text-muted" style="font-size: 11px;">#{{ user.id }}</span>
                            </div>
                        </div>
                    </b-th>
                    <b-th class="font-weight-normal">
                        {{ user.email }}
                    </b-th>
                    <b-th v-if="user.investments" class="font-weight-normal">{{ user.investments.formatMoney(0," "," ") }} 
                        <span v-if="user.investments > 0"> $</span>
                        <span v-else>0</span>
                    </b-th>
                    <b-th v-else class="font-weight-normal">0</b-th>
                    <b-th v-if="user.ths" class="font-weight-normal">{{ parseFloat(user.ths).formatMoney(2," "," ") }} TH/s</b-th>
                    <b-th v-else class="font-weight-normal">0.00 TH/s</b-th>
                    <b-th class="font-weight-normal text-nowrap">
                        <feather-icon 
                            :icon="Users.utils.resolveUserGroupIcon(user.group)" 
                            :class="Users.utils.resolveUserGroupIconColor(user.group)" 
                            size="18"  
                        />
                        {{ Users.utils.resolveUserGroupName(user.group) }}
                    </b-th>
                    <b-th class="font-weight-normal">
                        <!-- <b-badge v-if="user.is_verify" variant="light-success">
                            Активен
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            Не активен
                        </b-badge> -->

                        {{ user.register_date }} {{ localTime(user.created_timestamp) }}

                    </b-th>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-card-body v-if="!is_loading" class="d-flex justify-content-between pt-0 pb-0">
                <span class="font-weight-bolder">Всего: {{ dataSource.total_count }}</span>

                <b-pagination-nav 
                    :value="dataSource.current_page" 
                    :link-gen="linkGen" 
                    :number-of-pages="dataSource.last_page" 
                    use-router 
                ></b-pagination-nav>
        </b-card-body>
    </b-card>
    
</template>

<script>
    
    import Users from "@/modules/admin/users/"
    import User from "@/modules/user/"
    import moment from 'moment'

    export default {

        data() {
            return {
                Users,
                dataSource: {
                    items: [],
                    current_page: 1,
                    has_more_pages: false,
                    last_page: 1,
                    total_count: 0
                },    
                searchUsers: '',
                // disable_loading: false,
                is_loading: false,
                orderBy: {
                    sortBy: 'desc',
                    column: 'id'
                },
                filter: {
                    isBuy: false
                }
            }
        },
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'users' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            if( perms.read === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'users'
                    }
                });
            }
            
            next( true );
            
        },
        methods: {
            
            linkGen( pageNum ) {
                return {
                    name: this.$route.name,
                    query: {
                        page: pageNum
                    }
                }        
            },

            updateOrderBy(column) {
                
                if (this.orderBy.column == column) {
                    this.orderBy.sortBy = this.orderBy.sortBy == 'desc' ? 'asc' : 'desc'
                } else {
                    this.orderBy.sortBy = 'desc';
                    this.orderBy.column = column;
                }

                this.get(true);
            },

            localTime(timestamp) {
                return moment.utc(timestamp * 1000).local().format('HH:mm');
            },

            get(clear = false) {
                this.is_loading = true;
                if (clear) this.$route.query.page = 1

                this.$request.get("users/list", {
                    page: this.$route.query.page,
                    search: this.searchUsers,
                    orderby: this.orderBy,
                    filter: this.filter
                }).then( rsp => {
                    Object.keys( this.dataSource ).forEach( key => {
                        if( rsp.hasOwnProperty(key) ) {
                            this.dataSource[key] = rsp[key];
                        }
                    });
                    this.is_loading = false;
                });
            },

        //     handleScroll( e ) {     
                
        //         if(!this.dataSource.items) return;
        //         if(!this.dataSource.has_more_pages) return;
        //         if(this.is_loading ) return;
        //         if(this.disable_loading) return;
                
        //         let el = document.body;
        //         let doc = document.documentElement;
        //         let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
                               
        //         if( top + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                  
        //           this.is_loading = true;
                  
        //           this.$request.get("users/list", {
        //             id: this.$route.params.id,
        //             page:  this.dataSource.current_page + 1
        //             } ).then( result => {
        //                this.dataSource.has_more_pages = result.has_more_pages;
        //                this.dataSource.current_page = result.current_page;
        //                this.dataSource.items.push( ...result.items );
        //                this.is_loading = false;                       
        //             });
                  
        //         }
                
            // },
        },
        // created() {
        //     window.addEventListener("scroll", this.handleScroll );
        // },
        // destroyed() {
        //     window.removeEventListener("scroll", this.handleScroll );
        // },
        computed: {
            // filterDataSource() {
            //     return this.dataSource.items.filter(post => {
            //         return (
            //             (post.lastName !== null && post.lastName.toLowerCase().includes(this.searchUsers.toLowerCase())) ||
            //             (post.firstName !== null && post.firstName.toLowerCase().includes(this.searchUsers.toLowerCase())) ||
            //             post.email.toLowerCase().includes(this.searchUsers.toLowerCase())
            //         )
            //     })
            // },
        },
        components: {

        },
        watch: {
            // searchUsers: {
            //     handler(bef) {
            //         if (this.searchUsers.length !== 0) this.disable_loading = true;
            //         else this.disable_loading = false;
            //     }
            // },
            "$route.query" : function () {
                this.get();
            }
        },
        mounted() {
            this.get();
        }

    }

</script>